import React, { useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import Loader from '../Loader';
import Alert from '../Common/Alert';

const Feedback = (props) => {
  const { isOpen, response, feedbackId } = props;
  const [loaded, setLoaded] = useState(true);
  const [feedback, setFeedback] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [feedbackHeader, setFeedbackHeader] = useState('');
  const [messageClass, setMessageClass] = useState('');
  const [anonymousUser, setAnonymousUser] = useState(false);
  const modalStyle = {
    display: `${isOpen ? 'block' : 'none'}`,
    paddingRight: '4px',
  };

  const submitFeedback = () => {
    setLoaded(false);
    const baseUrl = `/api/feedback/SubmitFeedback/${feedback}/${anonymousUser}`;
    // Include feedbackId only if it is provided
    const url = feedbackId ? `${baseUrl}/${feedbackId}` : baseUrl;
    axios({
      method: 'PUT',
      url,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => {
        if (response.data === 'OK') {
          setSuccessMessage('Thank you for reaching out and providing us your valuable feedback.');
          setFeedbackHeader('Successfully submitted !!');
        }
        setLoaded(true);
        setFeedback('');
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          setLoaded(true);
          setErrorMessage('Something went wrong try again...');
          setFeedbackHeader('Error !!');
          setMessageClass('light-danger');
        }
      });
  };

  const handleFeedbackChange = (e) => {
    const { target } = e;
    setFeedback(target.value);
    setSuccessMessage('');
  };

  const resetFeedback = () => {
    setFeedback('');
    setErrorMessage('');
    setSuccessMessage('');
  };

  return (
    <div>
      {loaded
        ? (
          <div
            className={`modal fade ${isOpen ? 'show' : ''}`}
            id="logoutModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            style={modalStyle}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    {feedbackHeader || 'Please provide your valuable Feedback!'}
                  </h5>
                  <button
                    className="close"
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => { props.onChange(false); resetFeedback(); setAnonymousUser(false); }}
                  >
                    <span aria-hidden="true">x</span>
                  </button>
                </div>
                <div className="modal-body">
                  {!errorMessage && (successMessage === ''
                    ? (
                      <>
                        {
                          response && (
                            <>
                              <div className="mb-3 text-center">
                                <p className="mb-0">You voted</p>
                                {response === 'yes' && (
                                  <>
                                    <button type="button" className="btn btn-success py-2 px-3 mb-3">
                                      <FontAwesomeIcon
                                        icon={faThumbsUp}
                                        className="text-white"
                                      />
                                    </button>
                                    <h6>What do you like about Arcus!</h6>
                                  </>
                                )}
                                {response === 'no' && (
                                  <>
                                    <button type="button" className="btn btn-danger py-2 px-3 mb-3">
                                      <FontAwesomeIcon
                                        icon={faThumbsDown}
                                        className="text-white"
                                      />
                                    </button>
                                    <h6>How can we improve Arcus!</h6>
                                  </>
                                )}
                              </div>
                            </>
                          )
                        }
                        <textarea
                          className="form-control mb-3"
                          maxLength={500}
                          rows={5}
                          cols={70}
                          value={feedback}
                          onChange={handleFeedbackChange}
                          style={{ width: '100%' }}
                        />
                        <div className="form-check">
                          <label className="form-check-label">
                            <input type="checkbox" className="form-check-input" value="" onChange={() => setAnonymousUser(!anonymousUser)} />
                            Anonymous user
                          </label>
                        </div>
                      </>
                    )
                    : <span>{successMessage}</span>)}
                  {errorMessage
                    && (
                      <div className="col-12">
                        <Alert
                          message={errorMessage}
                          alertClassName={messageClass}
                          onClose={resetFeedback}
                        />
                      </div>
                    )}
                </div>
                <div className="modal-footer">
                  <button
                    className="btn btn-secondary"
                    type="button"
                    data-dismiss="modal"
                    onClick={() => { props.onChange(false); resetFeedback(); setAnonymousUser(false); }}
                  >
                    {errorMessage === '' && successMessage === '' ? 'Cancel' : 'OK'}
                  </button>
                  {errorMessage === '' && successMessage === ''
                    ? (
                      <button
                        className="btn btn-primary"
                        type="button"
                        data-dismiss="modal"
                        onClick={submitFeedback}
                        disabled={feedback === ''}
                      >
                        Submit
                      </button>
                    ) : ''}
                </div>
              </div>
            </div>
          </div>
        ) : <Loader />}
    </div>
  );
};

export default Feedback;
