import { combineReducers } from 'redux';
import ascendonReducer from './ascendonReducer';
import peopleReducer from './peopleReducer';
import accountReducer from './accountReducer';
import virtualNetworkReducer from './virtualNetworkReducer';
import vendorReducer from './vendorReducer';
import announcementReducer from './announcementReducer';
import awsServicesReducer from './awsServicesReducer';
import feedbackReducer from './feedbackReducer';

export default combineReducers({
  cloudabilityList: ascendonReducer,
  userProfile: peopleReducer,
  accountProfile: accountReducer,
  vnProfile: virtualNetworkReducer,
  vendorInfo: vendorReducer,
  announcementInfo: announcementReducer,
  awsServicesInfo: awsServicesReducer,
  feedbackList: feedbackReducer,
});
