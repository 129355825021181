import {
  GET_FEEDBACK,
  ERROR_GET_FEEDBACK,
  SAVE_LIKE_DISLIKE,
  ERROR_SAVE_LIKE_DISLIKE,
} from '../types';

const initialState = {
  feedbacks: [],
  feedbackLoading: true,
  errorFeedback: '',
  feedbackId: 0,
  error: '',
};

/* eslint-disable func-names */
export default function (state = initialState, action) {
  /* eslint-enable */
  switch (action.type) {
    case GET_FEEDBACK:
      return {
        ...state,
        feedbacks: action.payload,
        feedbackLoading: false,
      };
    case ERROR_GET_FEEDBACK:
      return {
        ...state,
        feedbackLoading: false,
        errorFeedback: action.payload.message,
      };
    case SAVE_LIKE_DISLIKE:
      return {
        ...state,
        feedbackId: action.payload,
      };
    case ERROR_SAVE_LIKE_DISLIKE:
      return {
        ...state,
        error: action.payload.message,
      };
    default: return state;
  }
}
