import React, { useState } from 'react';
import axios from 'axios';
import Loader from '../../Loader';

const SubReqPopup = (props) => {
  const {
    row, onCancel, data, type, message,
  } = props;
  const formattedData = (data.filter((i) => i.Id === row[0]))[0];
  const [loader, setLoader] = useState(false);

  const submit = (approvalType) => {
    setLoader(true);
    axios({
      method: 'PUT',
      url: `/api/azureSubscription/UpdateApproval/${row[0]}/${approvalType}`,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => {
        if (response.data === 'success') {
          message(approvalType);
          onCancel(false);
        }
        setLoader(false);
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          console.log(err);
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };
  return (
    <>
      <div
        className="modal fade reqNewAccount show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        style={{ display: 'block' }}
      >
        {
          loader
            ? <Loader />
            : (
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title" id="exampleModalLabel">
                      Subscription Request
                    </h4>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => onCancel('Cancel')}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped">
                        <tbody>
                          <tr>
                            <th>Subscription</th>
                            <td>{formattedData.Subscription}</td>
                          </tr>
                          <tr>
                            <th>Owner</th>
                            <td>{formattedData.Owner}</td>
                          </tr>
                          <tr>
                            <th>Requestor</th>
                            <td>{formattedData.requestor}</td>
                          </tr>
                          <tr>
                            <th>Team Email</th>
                            <td>{formattedData.Team_Email}</td>
                          </tr>
                          <tr>
                            <th>ITag</th>
                            <td>{formattedData.ITag}</td>
                          </tr>
                          <tr>
                            <th>Bussiness Justification</th>
                            <td>{formattedData.bussinessJustification}</td>
                          </tr>
                          <tr>
                            <th>Tenant ID</th>
                            <td>{formattedData.tenantId}</td>
                          </tr>
                          <tr>
                            <th>Tenant</th>
                            <td>{formattedData.tenant}</td>
                          </tr>
                          {
                            formattedData.supportGroup
                            && (
                            <tr>
                              <th>Support Group</th>
                              <td>{formattedData.supportGroup}</td>
                            </tr>
                            )
                          }
                          {
                            formattedData.managementGroup
                            && (
                            <tr>
                              <th>Management Group</th>
                              <td>{formattedData.managementGroup}</td>
                            </tr>
                            )
                          }
                        </tbody>
                      </table>
                    </div>
                    <div className="modal-footer">
                      {/* {type === 'new' && !alert */}
                      {type === 'new'
                        ? (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => submit('APPROVED')}
                            >
                              Approve
                            </button>

                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={() => submit('DENIED')}
                            >
                              Reject
                            </button>
                          </>
                        ) : ''}

                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => onCancel('Cancel')}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )

        }
      </div>
    </>
  );
};
export default SubReqPopup;
