import axios from 'axios';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import ApiRadioButtonList from '../Common/ApiRadioButtonList';
import ApiTypeAhead from '../Common/ApiTypeAhead';
import Input from '../Common/Input';
import Loader from '../Loader';
import Alert from '../Common/Alert';

const CreateAzureSubscription = (props) => {
  const defaultRequest = () => ({
    azureSubName: '',
    ownerId: '',
    ownerName: '',
    teamEmail: '',
    iTag: '',
    tenantId: '',
    tenant: '',
    productCodes: [],
    supportGroup: '',
    businessJustification: '',
    managementGroup: '',
  });

  const { label, title } = props;
  const [loaded, setLoaded] = useState(false);
  const [validateLoader, setValidateLoader] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [messageClass, setMessageClass] = useState('info');
  const [message, setMessage] = useState('');
  const [validation, setValidation] = useState({});
  const [request, setRequest] = useState(defaultRequest());
  const [validAzureSubName, setValidAzureSubName] = useState('');
  const [validITag, setValidITag] = useState(false);
  const [tenants, setTenants] = useState([]);
  const [productCodes, setProductCodes] = useState({ multiValue: [], filterOptions: [] });

  function getTenants() {
    axios
      .get(
        '/api/lookup/tenants',
      )
      .then((response) => {
        const result = response.data;
        setTenants(result);
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          setMessage(message.err);
        }
      });
  }
  function getProducts() {
    axios
      .get(
        '/api/lookup/products',
      )
      .then((response) => {
        const result = response.data;
        const productData = [];
        Object.keys(result).forEach((key) => {
          productData.push({ value: key, label: result[key] });
        });
        setProductCodes({
          ...productCodes,
          filterOptions: productData,
        });
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          setMessage(message.err);
        }
      });
  }
  useEffect(() => {
    getProducts();
    getTenants();
    setLoaded(true);
    setRequest(defaultRequest());
  }, []);

  const validEmail = (value) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value);

  const infoMessage = (
    <>
      <li>
        Ensure you have the budget approved and a proper iTag when requesting an
        azure subscription.
        {' '}
      </li>
    </>
  );

  function getTenantName(tenantId) {
    return tenantId !== '' ? tenants[tenantId].split('-')[0].toLowerCase() : '';
  }

  const validateAzureSubNameFormat = (tenant) => {
    const val = getTenantName(tenant);
    switch (val.trim()) {
      case 'dev':
      case 'corp':
        return request.azureSubName.includes('-') && request.azureSubName?.split('-')[1].length > 0 ? '' : 'Please Enter Azure Name in rand-sub-{customer}-{product}-tenant';
      case 'prod':
        return request.azureSubName.includes('-') && request.azureSubName?.split('-')[1].length > 0 ? '' : 'Please Enter Azure Name in cogs-sub-{customer}-{product}-tenant';
      default: return '';
    }
  };

  const getFormattedAcctName = (azSubName) => {
    const val = getTenantName(request.tenant);
    switch (val.trim()) {
      case 'dev': return `rand-sub-${azSubName}-dev`;
      case 'prod': return `cogs-sub-${azSubName}-prod`;
      case 'corp': return `rand-sub-${azSubName}-corp`;
      default: return azSubName;
    }
  };

  const validateAzureSubName = (name, value) => {
    setValidateLoader(true);
    const existMessage = 'This azure subscription name already exists';
    const formatedAccountName = getFormattedAcctName(value);
    axios
      .get(`/api/AzureSubscription/ValidateAzurSubName/${formatedAccountName}`)
      .then((response) => {
        setValidation({
          ...validation,
          [name]: value ? (response.data ? '' : existMessage) : 'Required *',
        });
        setValidateLoader(false);
        setValidAzureSubName(response.data ? '' : existMessage);
      })
      .catch((err) => {
        setMessage(err.message);
        setMessageClass('light-danger');
        setLoaded(true);
        setRequest(defaultRequest());
        setValidateLoader(false);
      });
  };

  const validateITag = (name, value) => {
    setValidateLoader(true);
    axios
      .get(`/api/accountRequest/ValidateITag/${value}`)
      .then((response) => {
        setValidation({
          ...validation,
          [name]: value ? (response.data ? '' : 'This iTag does not exist') : 'Required *',
        });
        setValidITag(!response.data);
        setValidateLoader(false);
      })
      .catch((err) => {
        setMessage(err.message);
        setMessageClass('light-danger');
        setLoaded(true);
        setRequest({ ...defaultRequest, iTag: value });
        setValidateLoader(false);
        setValidation({
          ...validation,
          [name]: '',
        });
      });
  };
  const handleProductChange = (item) => {
    setProductCodes({ ...productCodes, multiValue: item });
    setRequest({
      ...request,
      productCodes: item?.map((key) => key.value),
    });
    setValidation({
      ...validation,
      multiValue: item === null ? 'Required *' : '',
    });
  };

  const handleChange = (e) => {
    const { target } = e;
    const {
      name, type, value, checked,
    } = target;

    setRequest((ps) => {
      if (type === 'OptionsRadioButtonList' && value && value.length) {
        return {
          ...ps,
          [name]: value,
          azureSubName: '',
        };
      }
      return {
        ...ps,
        [name]: type === 'checkbox' ? checked : value,
      };
    });
    setValidation((v) => {
      if (type === 'OptionsRadioButtonList' && value && value.length) {
        setValidAzureSubName(validateAzureSubNameFormat(value));
        return {
          ...v,
          [name]: '',
          azureSubName: request.azureSubName !== '' && validateAzureSubNameFormat(value),
        };
      }
      return {
        ...v,
      };
    });
  };

  const handleValidation = (e) => {
    const { target } = e;
    const {
      name, required, value, type,
    } = target;

    if (required === true) {
      if (name === 'azureSubName' && value !== '') {
        if (request.tenant !== '') {
          const validateMsg = validateAzureSubNameFormat(request.tenant);
          if (validateMsg === '') {
            validateAzureSubName(name, value);
          } else {
            setValidation({
              ...validation,
              [name]: validateMsg,
            });
          }
          setValidAzureSubName(validateMsg);
        } else {
          setValidAzureSubName('Please Select Tenant First.');
          setValidation({
            ...validation,
            [name]: 'Please Select Tenant First.',
            tenant: 'Please choose tenant.',
          });
        }
      } else if (name === 'iTag' && value !== '') {
        validateITag(name, value);
      } else {
        setValidation({
          ...validation,
          [name]: value ? '' : 'Required *',
        });
      }
    }
    if (type === 'email' && value) {
      setValidation({
        ...validation,
        [name]: validEmail(value) ? '' : 'Invalid email address',
      });
    }
    if (name === 'businessJustification') {
      setValidation({
        ...validation,
        [name]: value ? '' : 'Required *',
      });
    }
  };

  const handleSubmit = () => {
    const { saveUrl, saveVerb } = props;
    const validation = {
      teamEmail: request.teamEmail
        ? validEmail(request.teamEmail)
          ? ''
          : 'Invalid email address'
        : "Please provide your team's email.",
      iTag: request.iTag
        ? ''
        : 'Please provide the iTag.',
      azureSubName: request.azureSubName
        ? validAzureSubName
        : 'Please provide the azure subscription name.',
      supportGroup: request.supportGroup
        ? ''
        : 'Please provide a support group',
      businessJustification: request.businessJustification
        ? ''
        : 'Please provide business justification',
      multiValue: request.productCodes && request.productCodes.length
        ? ''
        : 'Please provide a product code.',
      tenant:
        request.tenant && request.tenant.length
          ? ''
          : 'Please choose tenant.',
      ownerId:
        request.ownerId && request.ownerName
          ? ''
          : 'Please provide the subscription owner.',
    };
    const showMsg = false;
    const msg = 'Submitting form...';
    const msgClass = 'info';
    let validErrorExist = false;

    Object.keys(validation).forEach((o) => {
      if (validation[o]) {
        validErrorExist = true;
      }
    });

    const method = saveVerb || 'post';
    setValidation(validation);
    setLoaded(validErrorExist);
    setShowMessage(showMsg);
    setMessage(msg);
    setMessageClass(msgClass);
    if (!validErrorExist) {
      const formatRequest = {
        ...request,
        azureSubName: getFormattedAcctName(request.azureSubName),
        tenantId: request.tenant,
      };
      axios({
        method,
        url: `${saveUrl}`,
        data: JSON.stringify(formatRequest),
        headers: { 'Content-Type': 'application/json' },
      })
        .then(() => {
          const successMsg = (
            <div>
              You have successfully submitted an azure subscription request.
              <br />
            </div>
          );
          setShowMessage(true);
          setMessage(successMsg);
          setMessageClass('success');
          setRequest(defaultRequest());
          setLoaded(true);
        })
        .catch((err) => {
          if (err.name !== 'AbortError') {
            setMessage(err.message);
            setShowMessage(true);
            setMessageClass('light-danger');
            setRequest(defaultRequest());
            setLoaded(true);
          }
        });
    }
  };

  return (
    <>
      <div
        className="modal fade reqNewAccount show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        style={{ display: 'block' }}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header px-4">
              <h4 className="modal-title" id="exampleModalLabel">
                {title}
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => props.onCancel(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {loaded ? (
                <form className="form" noValidate>
                  <div className="row">
                    {showMessage ? (
                      <div className="col-12">
                        <Alert
                          message={message}
                          alertClass={messageClass}
                          onClose={() => setShowMessage(false)}
                        />
                      </div>
                    ) : (
                      <div className="col-12">
                        <Alert
                          message={infoMessage}
                          alertClass="info"
                          onClose={() => setShowMessage(false)}
                        />
                      </div>
                    )}
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <ApiRadioButtonList
                          name="tenant"
                          title="Tenant(s)"
                          requiredSign="*"
                          selectedItems={request.tenantId}
                          url="/api/lookup/tenants"
                          onChange={handleChange}
                          required
                          errorText={validation.tenant}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <Input
                          maxLength={255}
                          name="azureSubName"
                          value={request.azureSubName}
                          prepend={request.tenant !== '' ? <span className="input-group-text">{getTenantName(request.tenant).trim() === 'prod' ? 'cogs-sub-' : 'rand-sub-'}</span> : ''}
                          append={request.tenant !== '' ? (
                            <span className="input-group-text">
                              -
                              {getTenantName(request.tenant)}
                            </span>
                          ) : ''}
                          placeholder={request.tenant === '' ? 'Please Select Tenant First.' : '{product}-{customer}'}
                          title="Desired Azure Subscription Name"
                          requiredSign="*"
                          onChange={handleChange}
                          disableAutoComplete
                          required
                          onBlur={handleValidation}
                          errorText={validation.azureSubName}
                          disabled={request.tenant === ''}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <ApiTypeAhead
                          name="ownerId"
                          defaultId="ownerId"
                          displayKey="ownerName"
                          query={request.ownerName}
                          title="Azure Subscription Owner"
                          requiredSign="*"
                          placeholder="Start typing to search"
                          url="/api/lookup/people"
                          onChange={handleChange}
                          required
                          errorText={validation.ownerId}
                          onBlur={handleValidation}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <Input
                          maxLength={255}
                          name="managementGroup"
                          value={request.managementGroup}
                          placeholder="Management Group"
                          title="Management Group"
                          onChange={handleChange}
                          errorText={validation.managementGroup}
                          onBlur={handleValidation}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <Input
                          maxLength={255}
                          type="email"
                          name="teamEmail"
                          value={request.teamEmail}
                          placeholder="i.e. list-my-team@csgi.com"
                          title="Team Email (Distribution List)"
                          requiredSign=" *"
                          onChange={handleChange}
                          required
                          errorText={validation.teamEmail}
                          onBlur={handleValidation}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <Input
                          maxLength={10}
                          type="number"
                          name="iTag"
                          value={request.iTag}
                          prepend={<span className="input-group-text">i-</span>}
                          placeholder="i.e. 12345"
                          title="iTag"
                          requiredSign="*"
                          onChange={handleChange}
                          required
                          errorText={validation.iTag}
                          onBlur={handleValidation}
                          warning={validITag}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <label>
                        Product Code
                        {' '}
                        <label className="required-sign">*</label>
                      </label>
                      <div className="form-group">
                        <Select
                          id="productCodes"
                          title={`Product Codes ${<label className="required-sign">*</label>}`}
                          name="productCodes"
                          value={productCodes.multiValue}
                          options={productCodes.filterOptions}
                          onChange={handleProductChange}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          placeholder="Select Product Codes"
                          isMulti
                          required
                        />
                        {
                          validation.multiValue === '' ? '' : <div className="danger-font">{validation.multiValue}</div>
                        }
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <Input
                          maxLength={255}
                          name="supportGroup"
                          value={request.supportGroup}
                          placeholder="i.e. WFX or ACPx"
                          title="Support Group"
                          requiredSign="*"
                          onChange={handleChange}
                          required
                          errorText={validation.supportGroup}
                          onBlur={handleValidation}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-12">
                      <div className="form-group">
                        <Input
                          maxLength={255}
                          name="businessJustification"
                          value={request.businessJustification}
                          placeholder="Provide Business Justification"
                          title="Business Justification"
                          requiredSign="*"
                          onChange={handleChange}
                          required
                          errorText={validation.businessJustification}
                          onBlur={handleValidation}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              ) : (
                <Loader />
              )}
            </div>
            <div className="modal-footer px-4">
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={handleSubmit}
              >
                {label}
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => props.onCancel(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
        {validateLoader ? <Loader /> : ''}
      </div>
    </>
  );
};

CreateAzureSubscription.propTypes = {
  saveUrl: PropTypes.string.isRequired,
  user: PropTypes.shape({
    employeeId: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  }),
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default CreateAzureSubscription;
