import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import ApiCheckboxList from '../../Common/ApiCheckboxList';
import ApiRadioButtonList from '../../Common/ApiRadioButtonList';
import ApiTypeAhead from '../../Common/ApiTypeAhead';
import Checkbox from '../../Common/Checkbox';
import Input from '../../Common/Input';
import RadioButtons from '../../Common/RadioButtons';
import Loader from '../../Loader';
import Alert from '../../Common/Alert';

const CreateAccountRequest = (props) => {
  const defaultRequest = () => {
    const { user } = props;
    const { firstName, lastName, employeeId } = user || {};
    return {
      teamEmail: '',
      iTag: '',
      accountName: '',
      productCodes: [],
      environment: '',
      region: [],
      directConnectNeeded: null,
      supportGroup: '',
      accountNumber: null,
      ownerId: user ? employeeId : '',
      ownerName: user ? `${firstName} ${lastName}` : '',
      agreed: false,
      managerId: '',
      csgServiceOwnerId: '',
      executiveLevelId: '',
      vpId: '',
      managerName: '',
      csgServiceOwnerName: '',
      executiveLevelName: '',
      vpName: '',
    };
  };

  const { label, title } = props;
  const [loaded, setLoaded] = useState(false);
  const [validateLoader, setValidateLoader] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [messageClass, setMessageClass] = useState('info');
  const [message, setMessage] = useState('');
  const [validation, setValidation] = useState({});
  const [request, setRequest] = useState(defaultRequest());
  const [validAccounName, setValidAccounName] = useState('');
  const [validITag, setValidITag] = useState(false);
  const environments = [{ key: 1, value: '-prod' }, { key: 2, value: '-cte' }, { key: 3, value: ['qa', 'dev', 'test'] }, { key: 4, value: '-corp' }];
  const [productCodes, setProductCodes] = useState({ multiValue: [], filterOptions: [] });

  useEffect(() => {
    axios
      .get(
        '/api/lookup/products',
      )
      .then((response) => {
        const result = response.data;
        const productData = [];
        Object.keys(result).forEach((key) => {
          productData.push({ value: key, label: result[key] });
        });
        setProductCodes({
          ...productCodes,
          filterOptions: productData,
        });
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          console.error(err);
        }
      });
    setLoaded(true);
    setRequest(defaultRequest());
  }, []);

  const validEmail = (value) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value);

  const infoMessage = (
    <>
      <li>
        Ensure you have the budget approved and a proper iTag when requesting an
        account.
        {' '}
      </li>
      <li>
        Refer to the diagram on
        <a
          href="https://confluence.csgicorp.com/display/AWS/Request+New+AWS+Account"
          target="_blank"
          rel="noopener noreferrer"
        >
          {' '}
          this page
        </a>
        {' '}
        to understand the process of account creation.
      </li>
    </>
  );

  const validateAccNameFormat = (environment) => {
    switch (environment) {
      case '1':
      case '2':
      case '4':
        return request.accountName.includes('-') && request.accountName?.split('-')[1].length > 0 ? '' : 'Please Enter Account Name in {product}-{customer} format';
      case '3':
        // first will validate account name has follow for non prod format
        if (request.accountName?.split('-')?.length > 2) {
          const nonProdAccName = request.accountName.split('-')[request.accountName.split('-').length - 1].toLowerCase();
          return environments.filter((a) => a.key === Number(environment))[0].value.includes(nonProdAccName) ? '' : 'Please Add suffix (-qa, -dev or -test) in Account Name';
        }
        return 'Please Enter Account Name in {product}-{customer}-{environment} format';
      default: return '';
    }
  };

  const getFormattedAcctName = (acctName) => {
    switch (request.environment) {
      case '1': return `cogs-${acctName}-prod`;
      case '2': return `rand-${acctName}-cte`;
      case '3': return `rand-${acctName}`;
      case '4': return `rand-${acctName}-corp`;
      default: return acctName;
    }
  };

  const validateAccountName = (name, value) => {
    setValidateLoader(true);
    const existMessage = 'This account name is already exist';
    const formatedAccountName = getFormattedAcctName(value);
    axios
      .get(`/api/accountRequest/ValidateAccountName/${formatedAccountName}`)
      .then((response) => {
        setValidation({
          ...validation,
          [name]: value ? (response.data ? '' : existMessage) : 'Required *',
        });
        setValidateLoader(false);
        setValidAccounName(response.data ? '' : existMessage);
      })
      .catch((err) => {
        setMessage(err.message);
        setMessageClass('light-danger');
        setLoaded(true);
        setRequest(defaultRequest());
        setValidateLoader(false);
      });
  };

  const validateITag = (name, value) => {
    setValidateLoader(true);
    axios
      .get(`/api/accountRequest/ValidateITag/${value}`)
      .then((response) => {
        setValidation({
          ...validation,
          [name]: value ? (response.data ? '' : 'This iTag does not exist') : 'Required *',
        });
        setValidITag(!response.data);
        setValidateLoader(false);
      })
      .catch((err) => {
        setMessage(err.message);
        setMessageClass('light-danger');
        setLoaded(true);
        setRequest({ ...defaultRequest, iTag: value });
        setValidateLoader(false);
        setValidation({
          ...validation,
          [name]: '',
        });
      });
  };

  const handleProductChange = (item) => {
    setProductCodes({ ...productCodes, multiValue: item });
    setRequest({
      ...request,
      productCodes: item?.map((key) => key.value),
    });
    setValidation({
      ...validation,
      multiValue: item === null ? 'Required *' : '',
    });
  };

  const handleChange = (e) => {
    const { target } = e;
    const {
      name, type, value, checked,
    } = target;

    setRequest((ps) => {
      if ((type === 'checkbox' || type === 'radio') && checked === true) {
        return {
          ...ps,
          [name]: type === 'checkbox' ? checked : value,
        };
      }
      if ((type === 'OptionsCheckBoxList' || type === 'OptionsRadioButtonList') && value && value.length) {
        return {
          ...ps,
          [name]: value,
        };
      }
      if (type === 'search') {
        return {
          ...ps,
          [name]: value,
        };
      }
      return {
        ...ps,
        [name]: type === 'checkbox' ? checked : value,
      };
    });
    setValidation((v) => {
      if ((type === 'checkbox' || type === 'radio') && checked === true) {
        return {
          ...v,
          [name]: '',
        };
      }
      if (type === 'OptionsCheckBoxList' && value && value.length) {
        return {
          ...v,
          [name]: '',
        };
      }
      if (type === 'OptionsRadioButtonList' && value && value.length) {
        setValidAccounName(validateAccNameFormat(value));
        return {
          ...v,
          [name]: '',
          accountName: request.accountName !== '' && validateAccNameFormat(value),
        };
      }
      return {
        ...v,
      };
    });
  };

  const handleValidation = (e) => {
    const { target } = e;
    const {
      name, required, value, type,
    } = target;

    if (required === true) {
      if (name === 'accountName' && value !== '') {
        if (request.environment !== '') {
          const validateMsg = validateAccNameFormat(request.environment);
          if (validateMsg === '') {
            validateAccountName(name, value);
          } else {
            setValidation({
              ...validation,
              [name]: validateMsg,
            });
          }
          setValidAccounName(validateMsg);
        } else {
          setValidAccounName('Please Select Environment First.');
          setValidation({
            ...validation,
            [name]: 'Please Select Environment First.',
            environment: 'Please choose environment.',
          });
        }
      } else if (name === 'iTag' && value !== '') {
        validateITag(name, value);
      } else {
        setValidation({
          ...validation,
          [name]: value ? '' : 'Required *',
        });
      }
    }
    if (type === 'email' && value) {
      setValidation({
        ...validation,
        [name]: validEmail(value) ? '' : 'Invalid email address',
      });
    }
  };

  const handleSubmit = () => {
    const { saveUrl, saveVerb } = props;
    const validation = {
      teamEmail: request.teamEmail
        ? validEmail(request.teamEmail)
          ? ''
          : 'Invalid email address'
        : "Please provide your team's email.",
      iTag: request.iTag ? '' : 'Please provide the iTag.',
      accountName: request.accountName
        ? validAccounName
        : 'Please provide the desired account name.',
      supportGroup: request.supportGroup
        ? ''
        : 'Please provide a support group',
      multiValue: request.productCodes && request.productCodes.length ? '' : 'Please provide a product code.',
      environment:
        request.environment && request.environment.length
          ? ''
          : 'Please choose environment.',
      region:
        request.region && request.region.length
          ? ''
          : 'Please provide at least one region.',
      ownerId:
        request.ownerId && request.ownerName
          ? ''
          : 'Please provide the account owner.',
      managerId:
        request.managerId && request.managerName
          ? ''
          : 'Please provide the account manager.',
      csgServiceOwnerId:
        request.csgServiceOwnerId && request.csgServiceOwnerName
          ? ''
          : 'Please provide the account CSG service owner.',
      executiveLevelId:
        request.executiveLevelId && request.executiveLevelName
          ? ''
          : 'Please provide the account executive level.',
      vpId:
        request.vpId && request.vpName
          ? ''
          : 'Please provide the account vp.',
      directConnectNeeded:
        String(request.directConnectNeeded) === 'false'
          || String(request.directConnectNeeded) === 'true'
          ? ''
          : 'You must answer the question about Direct Connect',
      agreed: request.agreed === true
        ? ''
        : 'You must agree in order to request an account.',
    };
    const showMsg = false;
    const msg = 'Submitting form...';
    const msgClass = 'info';
    let validErrorExist = false;

    Object.keys(validation).forEach((o) => {
      if (validation[o]) {
        validErrorExist = true;
      }
    });

    const method = saveVerb || 'post';
    setValidation(validation);
    setLoaded(validErrorExist);
    setShowMessage(showMsg);
    setMessage(msg);
    setMessageClass(msgClass);

    if (!validErrorExist) {
      const formatRequest = {
        ...request,
        accountName: getFormattedAcctName(request.accountName),
      };
      // we are converting environment into array, server side have array type and using many places
      formatRequest.environment = [formatRequest.environment];
      axios({
        method,
        url: `${saveUrl}`,
        data: JSON.stringify(formatRequest),
        headers: { 'Content-Type': 'application/json' },
      })
        .then((response) => {
          const successMsg = (
            <div>
              You have successfully created an account request.
              <br />
              {response.data.key && (
                <>
                  <a
                    href={`https://jira.csgicorp.com/browse/${response.data.key}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    &nbsp;
                    {response.data.key}
                    &nbsp;
                  </a>
                  is created for you to track your request.
                </>
              )}
            </div>
          );
          setShowMessage(true);
          setMessage(successMsg);
          setMessageClass('success');
          setRequest(defaultRequest());
          setProductCodes([]);
          setLoaded(true);
        })
        .catch((err) => {
          if (err.name !== 'AbortError') {
            console.error(err);
            setShowMessage(true);
            setMessageClass('light-danger');
            setMessage('Something went wrong try again...');
            setRequest(defaultRequest());
            setLoaded(true);
          }
        });
    }
  };

  return (
    <>
      <div
        className="modal fade reqNewAccount show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        style={{ display: 'block' }}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header px-4">
              <h4 className="modal-title" id="exampleModalLabel">
                {title}
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => props.onCancel(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {loaded ? (
                <form className="form" noValidate>
                  <div className="row">
                    {showMessage ? (
                      <div className="col-12">
                        <Alert
                          message={message}
                          alertClass={messageClass}
                          onClose={() => setShowMessage(false)}
                        />
                      </div>
                    ) : (
                      <div className="col-12">
                        <Alert
                          message={infoMessage}
                          alertClass="info"
                          onClose={() => setShowMessage(false)}
                        />
                      </div>
                    )}
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <ApiRadioButtonList
                          name="environment"
                          title="Environment(s)"
                          selectedItems={request.environment}
                          url="/api/lookup/environments"
                          onChange={handleChange}
                          required
                          errorText={validation.environment}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <ApiCheckboxList
                          name="region"
                          title="Region(s)"
                          url="/api/lookup/regions"
                          selectedItems={request.region}
                          onChange={handleChange}
                          required
                          errorText={validation.region}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <Input
                          maxLength={255}
                          name="accountName"
                          value={request.accountName}
                          prepend={request.environment !== '' ? <span className="input-group-text">{request.environment === '1' ? 'cogs-' : 'rand-'}</span> : ''}
                          append={request.environment !== '3' && request.environment !== '' ? <span className="input-group-text">{environments.filter((a) => a.key === Number(request.environment[0]))[0].value}</span> : ''}
                          placeholder={request.environment === '' ? 'Please Select Environment First.' : (request.environment !== '3' && request.environment !== '' ? '{product}-{customer}' : '{product}-{customer}-{environment}')}
                          title="Desired Account Name"
                          onChange={handleChange}
                          disableAutoComplete
                          required
                          onBlur={handleValidation}
                          errorText={validation.accountName}
                          disabled={request.environment === ''}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <ApiTypeAhead
                          name="ownerId"
                          defaultId={request.ownerId}
                          displayKey="ownerName"
                          query={request.ownerName}
                          title="Account Contact/Owner"
                          placeholder="Start typing to search"
                          url="/api/lookup/people"
                          onChange={handleChange}
                          required
                          errorText={validation.ownerId}
                          onBlur={handleValidation}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <Input
                          maxLength={255}
                          type="email"
                          name="teamEmail"
                          value={request.teamEmail}
                          placeholder="i.e. list-my-team@csgi.com"
                          title="Team Email"
                          onChange={handleChange}
                          required
                          errorText={validation.teamEmail}
                          onBlur={handleValidation}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <Input
                          maxLength={10}
                          type="number"
                          name="iTag"
                          value={request.iTag}
                          prepend={<span className="input-group-text">i-</span>}
                          placeholder="i.e. 12345"
                          title="iTag"
                          onChange={handleChange}
                          required
                          errorText={validation.iTag}
                          onBlur={handleValidation}
                          warning={validITag}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <label>
                        Product Code
                      </label>
                      <div className="form-group">
                        <Select
                          id="productCodes"
                          title="Product Codes"
                          name="productCodes"
                          value={productCodes.multiValue}
                          options={productCodes.filterOptions}
                          onChange={handleProductChange}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          placeholder="Select Product Codes"
                          isMulti
                        />
                        {
                          validation.multiValue === '' ? '' : <div className="danger-font">{validation.multiValue}</div>
                        }
                      </div>

                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <Input
                          maxLength={255}
                          name="supportGroup"
                          value={request.supportGroup}
                          placeholder="i.e. WFX or ACPx"
                          title="Support Group"
                          onChange={handleChange}
                          required
                          errorText={validation.supportGroup}
                          onBlur={handleValidation}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <ApiTypeAhead
                          name="managerId"
                          defaultId={request.managerId}
                          displayKey="managerName"
                          query={request.managerName}
                          title="Account Manager/Director"
                          placeholder="Start typing to search"
                          url="/api/lookup/people"
                          onChange={handleChange}
                          required
                          errorText={validation.managerId}
                          onBlur={handleValidation}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <ApiTypeAhead
                          name="csgServiceOwnerId"
                          defaultId={request.csgServiceOwnerId}
                          displayKey="csgServiceOwnerName"
                          query={request.csgServiceOwnerName}
                          title="CSG Service Owner"
                          placeholder="Start typing to search"
                          url="/api/lookup/people"
                          onChange={handleChange}
                          required
                          errorText={validation.csgServiceOwnerId}
                          onBlur={handleValidation}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <ApiTypeAhead
                          name="executiveLevelId"
                          defaultId={request.executiveLevelId}
                          displayKey="executiveLevelName"
                          query={request.executiveLevelName}
                          title="Account Executive Level"
                          placeholder="Start typing to search"
                          url="/api/lookup/people"
                          onChange={handleChange}
                          required
                          errorText={validation.executiveLevelId}
                          onBlur={handleValidation}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <ApiTypeAhead
                          name="vpId"
                          defaultId={request.vpId}
                          displayKey="vpName"
                          query={request.vpName}
                          title="Account VP/EVP"
                          placeholder="Start typing to search"
                          url="/api/lookup/people"
                          onChange={handleChange}
                          required
                          errorText={validation.vpId}
                          onBlur={handleValidation}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-12">
                      <div className="form-group">
                        <RadioButtons
                          name="directConnectNeeded"
                          value={request.directConnectNeeded}
                          inline
                          options={{ true: 'Yes', false: 'No' }}
                          onChange={handleChange}
                          required
                          title="Needs direct connect to on-prem data center?"
                          errorText={validation.directConnectNeeded}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <Checkbox
                          name="agreed"
                          checked={request.agreed === true}
                          onChange={handleChange}
                          required
                          title="I agree that I am the responsible party"
                          errorText={validation.agreed}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              ) : (
                <Loader />
              )}
            </div>
            <div className="modal-footer px-4">
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={handleSubmit}
              >
                {label}
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => props.onCancel(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
        {validateLoader ? <Loader /> : ''}
      </div>
    </>
  );
};

CreateAccountRequest.propTypes = {
  saveUrl: PropTypes.string.isRequired,
  user: PropTypes.shape({
    employeeId: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  }),
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default CreateAccountRequest;
