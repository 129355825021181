import React, { useState } from 'react';

export const Context = React.createContext();

export const MenuContext = ({ children }) => {
  const [selectedMenu, setSelectedMenu] = useState('home');
  const [selectedSubMenu, setSelectedSubMenu] = useState('');
  const [showToast, setShowToast] = useState(false);

  return (
    <Context.Provider value={[selectedMenu, setSelectedMenu, selectedSubMenu, setSelectedSubMenu, showToast, setShowToast]}>
      {children}
    </Context.Provider>
  );
};
