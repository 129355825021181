// Ascendon Cloudability
export const GET_CLOUDABILITY_COST = 'GET_CLOUDABILITY_COST';
export const ERROR_CLOUDABILITY_COST = 'ERROR_CLOUDABILITY_COST';
export const GET_CLOUDABILITY_CUSTOMER_COST = 'GET_CLOUDABILITY_CUSTOMER_COST';
export const ERROR_CLOUDABILITY_CUSTOMER_COST = 'ERROR_CLOUDABILITY_CUSTOMER_COST';
export const GET_CUSTOMER_CSV = 'GET_CUSTOMER_CSV';
export const ERROR_CUSTOMER_CSV = 'ERROR_CUSTOMER_CSV';
export const GET_THIRTY_DAYS_COST = 'GET_THIRTY_DAYS_COST';
export const ERROR_THIRTY_DAYS_COST = 'ERROR_THIRTY_DAYS_COST';
export const GET_SIXTY_DAYS_COST = 'GET_SIXTY_DAYS_COST';
export const ERROR_SIXTY_DAYS_COST = 'ERROR_SIXTY_DAYS_COST';

// People User Profile
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const ERROR_USER_PROFILE = 'ERROR_USER_PROFILE';
export const GET_USER_TRAINING = 'GET_USER_TRAINING';
export const ERROR_USER_TRAINING = 'ERROR_USER_TRAINING';
export const GET_ACCOUNT_WITH_ROLE = 'GET_ACCOUNT_WITH_ROLE';
export const ERROR_ACCOUNT_WITH_ROLE = 'ERROR_ACCOUNT_WITH_ROLE';
export const SAVE_USER_PAGE_VISIT_HISTORY = 'SAVE_USER_PAGE_VISIT_HISTORY';
export const ERROR_USER_PAGE_VISIT_HISTORY = 'ERROR_USER_PAGE_VISIT_HISTORY';

// Ascendon Api Stats
export const GET_ASCD_STATS_COUNT = 'GET_ASCD_STATS_COUNT';
export const GET_ASCD_STATS_EXEC_TIME = 'GET_ASCD_STATS_EXEC_TIME';
export const GET_ASCD_STATS_DATA = 'GET_ASCD_STATS_DATA';
export const ERROR_ASCD_STATS = 'ERROR_ASCD_STATS';

// Account
export const GET_ACC_INFO = 'GET_ACC_INFO';
export const ERROR_ACC_INFO = 'ERROR_ACC_INFO';
export const GET_ACC_COST = 'GET_ACC_COST';
export const ERROR_ACC_COST = 'ERROR_ACC_COST';
export const GET_ACC_WITH_ROLE = 'GET_ACC_WITH_ROLE';
export const ERROR_ACC_WITH_ROLE = 'ERROR_ACC_WITH_ROLE';
export const GET_ACC_COMPLIANCE = 'GET_ACC_COMPLIANCE';
export const ERROR_ACC_COMPLIANCE = 'ERROR_ACC_COMPLIANCE';
export const GET_ACC_NETWORK = 'GET_ACC_NETWORK';
export const ERROR_ACC_NETWORK = 'ERROR_ACC_NETWORK';
export const GET_ACC_COMPUTE_INST = 'GET_ACC_COMPUTE_INST';
export const ERROR_ACC_COMPUTE_INST = 'ERROR_ACC_COMPUTE_INST';
export const GET_ACC_COMPUTE_SRVR = 'GET_ACC_COMPUTE_SRVR';
export const ERROR_ACC_COMPUTE_SRVR = 'ERROR_ACC_COMPUTE_SRVR';
export const GET_ACC_COMPUTE_CNTR = 'GET_ACC_COMPUTE_CNTR';
export const ERROR_ACC_COMPUTE_CNTR = 'ERROR_ACC_COMPUTE_CNTR';
export const GET_ACC_COMPUTE_STRG = 'GET_ACC_COMPUTE_STRG';
export const ERROR_ACC_COMPUTE_STRG = 'ERROR_ACC_COMPUTE_STRG';
export const GET_ACC_COMPUTE_LBCR = 'GET_ACC_COMPUTE_LBCR';
export const ERROR_ACC_COMPUTE_LBCR = 'ERROR_ACC_COMPUTE_LBCR';
export const GET_ACC_COMPUTE_DB = 'GET_ACC_COMPUTE_DB';
export const ERROR_ACC_COMPUTE_DB = 'ERROR_ACC_COMPUTE_DB';

// Virtual Network
export const GET_VN_COMPUTE_INST = 'GET_VN_COMPUTE_INST';
export const ERROR_VN_COMPUTE_INST = 'ERROR_VN_COMPUTE_INST';
export const GET_VN_COMPUTE_SRVR = 'GET_VN_COMPUTE_SRVR';
export const ERROR_VN_COMPUTE_SRVR = 'ERROR_VN_COMPUTE_SRVR';
export const GET_VN_COMPUTE_DB = 'GET_VN_COMPUTE_DB';
export const ERROR_VN_COMPUTE_DB = 'ERROR_VN_COMPUTE_DB';
export const GET_VN_COMPUTE_CONTR = 'GET_VN_COMPUTE_CONTR';
export const ERROR_VN_COMPUTE_CONTR = 'ERROR_VN_COMPUTE_CONTR';

// Vendor Mangament
export const GET_POST_EVENTS_DATA = 'GET_POST_EVENTS_DATA';
export const ERROR_POST_EVENTS_DATA = 'ERROR_POST_EVENTS_DATA';
export const GET_STOCK_WIDGETS_DATA = 'GET_STOCK_WIDGETS_DATA';
export const ERROR_STOCK_WIDGETS_DATA = 'ERROR_STOCK_WIDGETS_DATA';
export const GET_HEALTH_EVENTS_DATA = 'GET_HEALTH_EVENTS_DATA';
export const ERROR_HEALTH_EVENTS_DATA = 'ERROR_HEALTH_EVENTS_DATA';
export const GET_REGION_SERVICES_DATA = 'GET_REGION_SERVICES_DATA';
export const ERROR_REGION_SERVICES_DATA = 'ERROR_REGION_SERVICES_DATA';
export const GET_UNAVAILABLE_SERVICES_DATA = 'GET_UNAVAILABLE_SERVICES_DATA';
export const ERROR_UNAVAILABLE_SERVICES_DATA = 'ERROR_UNAVAILABLE_SERVICES_DATA';

// Announcement
export const UPDATE_USER_ANNOUNCEMENT_MAAPING = 'UPDATE_USER_ANNOUNCEMENT_MAAPING';
export const ERROR_UPDATE_USER_ANNOUNCEMENT_MAAPING = 'ERROR_UPDATE_USER_ANNOUNCEMENT_MAAPING';

// AWS Service Cost
export const GET_CUMULATIVE_SERVICE_COST = 'GET_CUMULATIVE_SERVICE_COST';
export const ERROR_CUMULATIVE_SERVICE_COST = 'ERROR_CUMULATIVE_SERVICE_COST';
export const GET_ACCOUNT_VIEW_LIST = 'GET_ACCOUNT_VIEW_LIST';
export const ERROR_ACCOUNT_VIEW_LIST = 'ERROR_ACCOUNT_VIEW_LIST';
export const GET_ASCD_TIME_PERIODS = 'GET_ASCD_TIME_PERIODS';
export const ERROR_ASCD_TIME_PERIODS = 'ERROR_ASCD_TIME_PERIODS';
export const GET_AWS_SERVICE_COST_DETAILS = 'GET_AWS_SERVICE_COST_DETAILS';
export const ERROR_AWS_SERVICE_COST_DETAILS = 'ERROR_AWS_SERVICE_COST_DETAILS';

// Aws Service Usage
export const GET_SERVICE_LIST = 'GET_SERVICE_LIST';
export const ERROR_SERVICE_LIST = 'ERROR_SERVICE_LIST';
export const GET_COUNT_AWS_SERVICES = 'GET_COUNT_AWS_SERVICES';
export const ERROR_COUNT_AWS_SERVICES = 'ERROR_COUNT_AWS_SERVICES';
export const SET_SERVICE_LIST_LOADING = 'SET_SERVICE_LIST_LOADING';
export const SET_AWS_COUNT_LOADING = 'SET_AWS_COUNT_LOADING';
export const GET_ACCOUNT_LIST = 'GET_ACCOUNT_LIST';
export const ERROR_ACCOUNT_LIST = 'ERROR_ACCOUNT_LIST';
export const SET_ACCOUNT_LIST_LOADING = 'SET_ACCOUNT_LIST_LOADING';
export const GET_SERVICES_BY_ACCOUNT = 'GET_SERVICES_BY_ACCOUNT';
export const ERROR_SERVICES_BY_ACCOUNT = 'ERROR_SERVICES_BY_ACCOUNT';
export const SET_SERVICES_BY_ACCOUNT_LOADING = 'SET_SERVICES_BY_ACCOUNT_LOADING';
export const GET_ACCOUNT_SERVICES_MAPPING_USAGE = 'GET_ACCOUNT_SERVICES_MAPPING_USAGE';
export const SET_ACCOUNT_SERVICES_MAPPING_USAGE_LOADING = 'SET_ACCOUNT_SERVICES_MAPPING_USAGE_LOADING';
export const ERROR_ACCOUNT_SERVICES_MAPPING_USAGE = 'ERROR_ACCOUNT_SERVICES_MAPPING_USAGE';

// FeedbackModuleTypes
export const GET_FEEDBACK = 'GET_FEEDBACK';
export const ERROR_GET_FEEDBACK = 'ERROR_GET_FEEDBACK';
export const SAVE_LIKE_DISLIKE = 'SAVE_LIKE_DISLIKE';
export const ERROR_SAVE_LIKE_DISLIKE = 'ERROR_SAVE_LIKE_DISLIKE';
