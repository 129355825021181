import axios from 'axios';
import {
  GET_FEEDBACK,
  ERROR_GET_FEEDBACK,
  SAVE_LIKE_DISLIKE,
  ERROR_SAVE_LIKE_DISLIKE,
} from '../types';

export const getFeedbacks = (apiUrl) => async (dispatch) => {
  try {
    const res = await axios.get(apiUrl);
    dispatch({
      type: GET_FEEDBACK,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_GET_FEEDBACK,
      payload: error,
    });
  }
};
export const saveIsLike = (apiUrl) => async (dispatch) => {
  try {
    const res = await axios.put(apiUrl);
    dispatch({
      type: SAVE_LIKE_DISLIKE,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_SAVE_LIKE_DISLIKE,
      payload: error,
    });
  }
};
