import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toast, { ToastBar, Toaster } from 'react-hot-toast';
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Feedback from '../Feedback';
import { saveIsLike } from '../../store/actions/feedbackAction';

const notify = () => {
  toast(() => (
    <div>
      <h6>Your input matters!!!</h6>
      <p className="mb-0">Is Arcus Application helpful to you?</p>
    </div>
  ));
};

const Toast = () => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState('');
  const [showFeedbackModel, setShowFeedbackModel] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const feedbackList = useSelector((state) => state.feedbackList);
  const { feedbackId } = feedbackList;

  const submitIsLike = (value) => {
    const apiUrl = `/api/feedback/saveIsLike/${value}`;
    dispatch(saveIsLike(apiUrl));
  };

  useEffect(() => {
    if (feedbackId > 0) {
      setShowFeedbackModel(true);
      setLoaded(true);
    }
  }, [feedbackId]);

  const handleIsLike = (t, val) => {
    setResponse(val);
    submitIsLike(val);
    toast.dismiss(t.id);
  };

  const handleFeedbackChange = () => {
    setResponse('');
    setShowFeedbackModel(false);
    setLoaded(false);
  };

  useEffect(() => {
    notify();
  }, []);

  return (
    <>
      <Toaster
        position="bottom-right"
        toastOptions={{
          duration: 60000,
          className: 'custom-toaster',
        }}
      >
        {(t) => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <>
                {icon}
                {message}
                {t.type !== 'loading' && (
                  <>
                    <button type="button" className="btn px-2" onClick={() => handleIsLike(t, 'yes')}>
                      <FontAwesomeIcon
                        icon={faThumbsUp}
                        className="text-success"
                      />
                    </button>
                    <button type="button" className="btn px-2" onClick={() => handleIsLike(t, 'no')}>
                      <FontAwesomeIcon
                        icon={faThumbsDown}
                        className="text-danger"
                      />
                    </button>
                    <div className="toast-progress active" style={{ animation: 'toast-progress 60s linear forwards' }} />
                  </>
                )}
              </>
            )}
          </ToastBar>
        )}
      </Toaster>
      {loaded ? (
        <>
          <div className="log-out-layer" />
          <Feedback isOpen={showFeedbackModel} onChange={handleFeedbackChange} response={response} feedbackId={feedbackId} />
        </>
      ) : ''}
    </>
  );
};

export default Toast;
